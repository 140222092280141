import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, IconButton, Theme } from "@mui/material";
import Iframe from "react-iframe";
import { Icon } from "@iconify/react";
import { red } from "@mui/material/colors";
import styled from "@emotion/styled";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: true | false;
  close: () => void;
  gameUrl: string | "";
};

const CloseIconButton = styled(IconButton)(({ theme }: { theme?: Theme }) => ({
  backgroundColor: red[700],
  color: theme?.palette.common.white,
  boxShadow: theme?.shadows[4],
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    backgroundColor: red[900],
  },
  "&:active": {
    transform: "scale(0.98)",
    backgroundColor: red[800],
  },
}));

export default function GameLauncherDialog({ open, close, gameUrl }: Props) {
  const [config] = useConfigStore((state) => [state.config], shallow);
  const handleClose = () => {
    close();
  };

  const blackList = ['pg1play', 'the1laos', 'hengmak168', 'dragonheng168', 'deemark168']
  const hasBlackList = blackList.find((agent: any) => agent === config?.agent_name)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box height={"100%"} position="relative">
        {
          !hasBlackList && (
            <Box position="absolute" top={0} left={0} zIndex={1} p={2}>
              <CloseIconButton onClick={close} color="error" size="large">
                <Icon icon="grommet-icons:power-shutdown" />
              </CloseIconButton>
            </Box>
          )
        }
        <Box position="relative" height="100%">
          {gameUrl && (
            <Iframe
              url={gameUrl}
              frameBorder={0}
              width="100%"
              height="100%"
              display="block"
              position="relative"
            />
          )}
        </Box>
      </Box>
    </Dialog >
  );
}
